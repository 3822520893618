// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// var $ = require("jquery")
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
require("popper.js")
require("bootstrap")
require('tableexport.jquery.plugin')
require("bootstrap-table")
require("bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control")
require("bootstrap-table/dist/extensions/export/bootstrap-table-export")
require("bootstrap-datepicker")
require("@fortawesome/fontawesome-free/js/all")
require('@bitquery/charts')

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

